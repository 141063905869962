export const dictTranslations = { 
  Pricing: "Pricing",
  Features: "Features",
  dict: {
    genre: {
      it: "IT",
      legal: "Legal",
      life_sciences: "Life Sciences",
      marketing: "Marketing",
      other: "Other",
      sports: "Sports & Entertainment",
      technical: "Technical"
    },
    role: {
      ROLE_BUYER: "Requester",
      ROLE_ENHANCER: "Enhancer",
      ROLE_LANGUAGE_MINER: "Language Miner",
      ROLE_PLAIN: "Exfluency User",
      ROLE_POLISHER: "Polisher",
      ROLE_REDACTOR: "Redactor",
      ROLE_TRUST_MINER: "Trust Miner",
      ROLE_BI: "BI User",
      ROLE_FINANCE: "Finance",
      ROLE_EXFLUENCY_ADMIN: "Exfluency Admin",
      ROLE_COMMUNITY_MANAGER: "Community Manager",
      ROLE_PLATFORM_MANAGER: "Platform Manager",
      ROLE_SUPPORT: "Support",
      ROLE_GATED_COMMUNITY_MANAGER: "Gated Community Manager", 
      priorityType: {
        0: 'Allowed',
        1: 'Preferred'
      }
    },
    file: {
      RESULT: "Result File",
      XLIFF2: "Bilingual XLIFF 2.0",
      XLIFF1: "Bilingual XLIFF 1.2",
      TRANSLATED_DOCX: "Translated docx",
      BILANG_DOCX: "Bilingual docx",
      RESULT_ANON: "Anonymized Result File",
      XLIFF2_ANON: "Anonymized Bilingual XLIFF 2.0",
      XLIFF1_ANON: "Anonymized Bilingual XLIFF 1.2",
      TRANSLATED_DOCX_ANON: "Anonymized Translated docx",
      BILANG_DOCX_ANON: "Anonymized Bilingual docx",
      CORRECTION: "Corrected translation",
      CSV: ".csv",
      OCR: "OCR docx File",
      REDACTION: "Redacted file",
      RECYCLING: "Immediate translation",
      ENHANCEMENT: "Enhanced translation",
      TRUST_MINING: "Trust Mined translation",
      FINAL_REDACTION: "File after Final Redaction",
      TCERT: "Translation Certificate",
      OTHER: "Project summary",
      ATTACHMENT: "Download"
    },
    featureParam: {
      ExecutorsPoolForStage: "Executors pool chosen for stage",
      MaxExecutorsToReassign: "Max number of executors which can be automatically reassign",
      RedactorMaxProjects: "Max amount of projects for Redactor",
      EnhancerMaxProjects: "Max amount of projects for Enhancer",
      TrustMinerMaxProjects: "Max amount of projects for Trust Miner",
      RedactorFee: "Fixed Redactor fee for redaction",
      WordsPerHour: "(Fee calculation) Default words per hour",
      RedactionWordsPerHour: "(Fee calculation) Default words per hour for Redaction",
      FinalRedactionWordsPerHour: "(Fee calculation) Default words per hour for Final Redaction",
      RedactionEfficiencyMultiplier: "(Fee calculation) Redaction Efficiency Multiplier",
      EnhancementEfficiencyMultiplier: "(Fee calculation) Enhancement Efficiency Multiplier",
      TrustMiningEfficiencyMultiplier: "(Fee calculation) TrustMining Efficiency Multiplier",
      ExecutorsInvolvedExfluencyFee: "(Fee calculation) Exfluency fee when any executor is involved DEPRECATED",
      OnlyImmediateExfluencyFee: "(Fee calculation) Exfluency fee when only immediate translation DEPRECATED",
      MinimumAssignmentPrice: "(Fee calculation) Minimum price for an assignment",
      EnhancerDefaultTrustChainScore: "(For test only) Default Trust Chain Score (-1.0 to ignore enhancers without TC Score)",
      MinHourlyRate: "Minimum allowed Hourly Rate limit",
      MaxHourlyRate: "Maximum allowed Hourly Rate limit",
      PercentageOfTimeRemainingInAssignment: "(Soon to be late) Percentage of time remaining in assignment",
      MinutesUntilAssignmentDeadline: "(Soon to be late) Minutes until assignment deadline",
      DelayMinutesToColorJobsWithPendingAssignments: "(Soon to be late) Delay Minutes to Color Jobs with Pending Assignments",
      MinCompanyPaymentThreshold: "Global Minimum Payment Threshold",
      MinutesUntilAssignmentDeadlineWhenReminderIsSent: "Minutes until assignment deadline when reminder is sent",
      DeadlineBufferSize: "Deadline buffer size (in minutes)",
      TempoCalculationAssignmentsLimit: "Assignments limit for executor tempo calculation"
    },
    featureSwitch: {
      AllowNewUsersSignUp: "Allow new users to join Exfluency",
      UseEngine_Amazon: "Use engine - Amazon",
      UseEngine_Google: "Use engine - Google",
      UseEngine_IQVIA: "demo" == (process.env.REACT_APP_ENVIRONMENT || 'dev') ? "Use engine - Custom" : "Use engine - IQVIA",
      UseEngine_Microsoft: "Use engine - Microsoft",
      UseEngine_ModernMT: "Use engine - ModernMT",
      UseEngine_DeepL: "Use engine - DeepL",
      UseEngine_Alibaba: "Use engine - Alibaba",
      EnableAnonymization: "Enable Anonymization",
      EnableLangCoinOnUI: "Show LangCoin-related information",
      DisableRequesterNotifications: 'Disable Requester Notifications',
      EnableNewEditor: 'Enable New Editor',
      PushBackDeadlinesByWeekends: 'Push back deadlines by weekends',
      NeverUsePlainTextHitsForFormattedAssets: 'Never use plain-text hits for formatted assets',
      UseNewTaxonomyService: 'Use new Taxonomy Service'
    },
    lang: {
      AF: "Afrikaans",
      AK: "Twi (Akan)",
      AM: "Amharic",
      AR: "Arabic",
      AS: "Assamese",
      AY: "Aymara",
      AZ: "Azeri / Azerbaijani (Latin)",
      BA: "Bashkir",
      BE: "Belarusian",
      BG: "Bulgarian",
      BHO: "Bhojpuri",
      BM: "Bambara",
      BN: "Bengali",
      BO: "Tibetan",
      BS: "Bosnian",
      CA: "Catalan",
      CEB: "Cebuano",
      CKB: "Kurdish (Central)",
      CO: "Corsican",
      CS: "Czech",
      CY: "Welsh",
      DA: "Danish",
      DE_AT: "German (Austria)",
      DE_CH: "German (Switzerland)",
      DE_DE: "German (Germany)",
      DOI: "Dogri",
      DV: "Dhivehi",
      EE: "Ewe",
      EL_CY: "Greek (Cyprus)",
      EL_GR: "Greek (Greece)",
      EN_GB: "English (United Kingdom)",
      EN_US: "English (United States)",
      EO: "Esperanto",
      ES: "Spanish",
      ES_419: "Spanish (Latin America)",
      ES_MX: "Spanish (Mexico)",
      ET: "Estonian",
      EU: "Basque",
      FA: "Farsi",
      FA_AF: "Farsi (Afghanistan)",
      FA_IR: "Farsi (Iran)",
      FI: "Finnish",
      FIL: "Filipino",
      FJ: "Fijian",
      FO: "Faroese",
      FR_BE: "French (Belgium)",
      FR_CA: "French (Canada)",
      FR_CH: "French (Switzerland)",
      FR_FR: "French (France)",
      FY: "Western Frisian",
      GA: "Irish",
      GD: "Scottish Gaelic",
      GL: "Galician",
      GN: "Guarani",
      GOM: "Konkani",
      GU: "Gujarati",
      HA: "Hausa",
      HE: "Hebrew",
      HI: "Hindi",
      HMN: "Hmong",
      HR: "Croatian",
      HT: "Haitian",
      HU: "Hungarian",
      HY: "Armenian",
      ID: "Indonesian",
      IG: "Igbo",
      ILO: "Ilocano",
      IS: "Icelandic",
      IT_CH: "Italian (Switzerland)",
      IT_IT: "Italian (Italy)",
      JA: "Japanese",
      JV: "Javanese",
      KA: "Georgian",
      KK: "Kazakh",
      KL: "Greenlandic",
      KLN_KE: "Kalenjin",
      KM: "Khmer",
      KMR: "Kurdish (Northern)",
      KN: "Kannada",
      KO: "Korean",
      KS: "Kashmiri",
      KU: "Kurdish",
      KU_ARAB: "Kurdish (Arabic)",
      KY_KG: "Kyrgyz (Kyrgyzstan)",
      KZ: "Kyrgyz",
      LA: "Latin",
      LB: "Luxembourgish",
      LG: "Luganda",
      LN: "Lingala",
      LO: "Lao",
      LT: "Lithuanian",
      LUS: "Mizo",
      LV: "Latvian",
      MAI: "Maithili",
      MG: "Malagasy",
      MI: "Maori",
      MK: "Macedonian",
      ML: "Malayalam",
      MN: "Mongolian (Cyrillic)",
      MN_MONG: "Mongolian (Traditional)",
      MNI: "Manipuri",
      MR: "Marathi",
      MS: "Malay",
      MT: "Maltese",
      MY: "Burmese",
      NE: "Nepali",
      NL_BE: "Dutch (Belgium)",
      NL_NL: "Dutch (Netherlands)",
      NO: "Norwegian",
      NSO: "Sepedi",
      NY: "Chewa",
      OM: "Oromo",
      OR: "Odia (Oriya)",
      PA_ARAB_PK: "Punjabi (Pakistan, Arabic)",
      PA_IN: "Punjabi (India)",
      PA_PK: "Punjabi (Pakistan)",
      PL: "Polish",
      PRS: "Dari",
      PS: "Pashto",
      PT_BR: "Portuguese (Brazil)",
      PT_PT: "Portuguese (Portugal)",
      QU: "Quechua",
      RO_MD: "Romanian (Moldova)",
      RO_RO: "Romanian (Romania)",
      RU_RU: "Russian (Russia)",
      RU_UA: "Russian (Ukraine)",
      RUN: "Rundi",
      RW: "Kinyarwanda",
      SA: "Sanskrit",
      SD: "Sindhi",
      SI: "Sinhala",
      SK: "Slovak",
      SL: "Slovenian",
      SM: "Samoan",
      SN: "Shona",
      SO: "Somali",
      SQ: "Albanian",
      SR_CYRL: "Serbian (Cyrillic)",
      SR_LATN: "Serbian (Latin)",
      ST: "Southern Sotho",
      SU: "Sundanese",
      SV_FI: "Swedish (Finland)",
      SV_SE: "Swedish (Sweden)",
      SW: "Swahili",
      TA: "Tamil",
      TA_IN: "Tamil (India)",
      TE: "Telugu",
      TG: "Tajik",
      TG_CYRL: "Tajik (Cyrillic)",
      TH: "Thai",
      TI: "Tigrinya",
      TK: "Turkmen (Latin)",
      TL: "Tagalog",
      TLH: "Klingon",
      TN: "Tswana",
      TO: "Tongan",
      TR: "Turkish",
      TS: "Tsonga",
      TT: "Tatar",
      UG: "Uyghur (Arabic)",
      UK: "Ukrainian",
      UR: "Urdu",
      UZ_CYRL_UZ: "Uzbek (Cyrillic)",
      UZ_LATN_UZ: "Uzbek (Latin)",
      VI: "Vietnamese",
      XH: "Xhosa",
      YI: "Yiddish",
      YO: "Yoruba",
      ZH_HANS: "Simplified Chinese (China)",
      ZH_HANT: "Traditional Chinese (Taiwan)",
      ZH_HK: "Traditional Chinese (Hong Kong)",
      ZH_SG: "Simplified Chinese (Singapore)",
      ZU: "Zulu"
    },
    recyclableTranslationLevels: {
      ENHANCED: "Enhanced",
      TRUST_MINED: "Trust Mined"
    }
  }
}