import React from 'react'
import 'styles.scss'

import { ReactComponent as RedX } from 'icons/filter_red-x.svg'
import { ReactComponent as GreenPlus } from 'icons/filter_green-plus.svg'
import { ReactComponent as GrayX } from 'icons/filter_grey-x.svg'
import { ReactComponent as GrayPlus } from 'icons/filter_grey-plus.svg'
import { ReactComponent as Edit } from 'icons/icon_edit.svg'

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

type FilterVariants = 'red-x' | 'green-plus' | 'gray-x' | 'gray-plus'

interface FilterIconProps extends DivProps {
  type: FilterVariants
}

export const FilterIcon = (props: FilterIconProps) => {
  const { type, ...rest } = props

  const getIcon = (type: FilterVariants) => {
    switch (type) {
      case 'red-x':
        return RedX
      case 'green-plus':
        return GreenPlus
      case 'gray-x':
        return GrayX
      case 'gray-plus':
        return GrayPlus
      default:
        return RedX
    }
  }

  const Icon = getIcon(type)
  
  return <div {...rest} className='icon'>
    <Icon />
  </div>
}

export const EditIcon = (props: DivProps) => {
  return <div {...props} className='edit-icon'>
    <Edit />
  </div>
}