import React from 'react'
import { Datagrid, EditButton, List, TextField } from 'react-admin'

import CustomTranslatedTextField from 'common/CustomTranslatedTextField'
import CustomPagination from 'common/CustomPagination'

const PriceList = () => {
  return <List perPage={50} sort={{ field: 'name', order: 'DESC' }} exporter={false} pagination={<CustomPagination />}>
    <Datagrid bulkActionButtons={false}>
      <CustomTranslatedTextField source="id" label={'Feature'} sortable={false} translationPath="dict.featureParam." />
      <TextField source='value' label={'Value'} sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
}

export default React.memo(PriceList)